import React, { useState, useRef, useEffect } from 'react'
import EnhancedInput from './components/EnhancedInput'
import { Button } from './components/ui/button'
import { ChevronDown, ChevronUp, Plus } from 'lucide-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faHome } from '@fortawesome/free-solid-svg-icons'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import latex from 'react-syntax-highlighter/dist/esm/languages/hljs/latex'
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import LanguageSelector from './components/LanguageSelector'
import './App.css'

const App = () => {
    const [language, setLanguage] = useState(() => {
        const params = new URLSearchParams(window.location.search)
        const langParam = params.get('lang')
        return langParam === 'fr' ? 'fr' : 'en'
    })

    const [nodes, setNodes] = useState([{ id: 'A', label: 'A', options: '' }])
    const [edges, setEdges] = useState([])
    const [nextEdgeId, setNextEdgeId] = useState(0)
    const [latexCode, setLatexCode] = useState('')
    const [graphLayout, setGraphLayout] = useState('simple necklace layout')
    const [nodeShape, setNodeShape] = useState('circle')
    const [edgeColor, setEdgeColor] = useState('black')
    const [isDirected, setIsDirected] = useState(false)
    const [globalNodeOptions, setGlobalNodeOptions] = useState('')
    const [globalEdgeOptions, setGlobalEdgeOptions] = useState('')
    const [graphOptions, setGraphOptions] = useState('')
    const [nodeBackgroundColor, setNodeBackgroundColor] = useState('white')
    const [nodeFrameColor, setNodeFrameColor] = useState('black')
    const [nodeTextColor, setNodeTextColor] = useState('black')
    const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useState(false)
    const [notification, setNotification] = useState(null)
    const [isCompleteGraph, setIsCompleteGraph] = useState(true)

    SyntaxHighlighter.registerLanguage('latex', latex)

    const AddButton = ({ onClick, title }) => (
        <Button
            onClick={onClick}
            className="inline-flex justify-center items-center p-1 border border-[#5465b7] rounded-full shadow-sm text-white bg-[#5465b7] hover:bg-white hover:text-[#5465b7] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5465b7] transition-colors duration-200"
            title={title}
            style={{ width: '28px', height: '28px', padding: '0' }}
        >
            <Plus size={18} />
        </Button>
    )

    const t = (key) => translations[language][key] || key

    useEffect(() => {
        const url = new URL(window.location)
        url.searchParams.set('lang', language)
        window.history.pushState({}, '', url)
    }, [language])

    const toggleLanguage = () => {
        const newLang = language === 'en' ? 'fr' : 'en'
        setLanguage(newLang)
        const url = new URL(window.location)
        url.searchParams.set('lang', newLang)
        window.history.pushState({}, '', url)
    }

    const edgeRefs = useRef({})

    useEffect(() => {}, [nodes])

    const updateNode = (index, field, value) => {
        const newNodes = [...nodes]
        newNodes[index][field] = value
        setNodes(newNodes)
    }

    const deleteNode = (index) => {
        const nodeToDelete = nodes[index]
        setNodes(nodes.filter((_, i) => i !== index))

        setEdges(edges.filter((edge) => edge.from !== nodeToDelete.id && edge.to !== nodeToDelete.id))
    }

    const addEdge = () => {
        const newEdgeId = nextEdgeId
        setEdges([
            ...edges,
            {
                id: newEdgeId,
                from: '',
                to: '',
                label: '',
                options: '',
                isDirected: false,
            },
        ])
        setNextEdgeId(nextEdgeId + 1)

        setIsCompleteGraph(false)

        setTimeout(() => {
            if (edgeRefs.current[newEdgeId]) {
                edgeRefs.current[newEdgeId].focus()
            }
        }, 0)
    }

    const updateEdge = (id, field, value) => {
        setEdges(edges.map((edge) => (edge.id === id ? { ...edge, [field]: value } : edge)))
    }

    const updateGlobalDirected = (checked) => {
        setIsDirected(checked)
        if (isCompleteGraph) {
            setEdges(generateCompleteGraphEdges(nodes, checked))
        } else {
            setEdges((prevEdges) =>
                prevEdges.map((edge) => ({
                    ...edge,
                    isDirected: checked,
                }))
            )
        }
    }

    const addNode = () => {
        const newNodeId = String.fromCharCode(65 + nodes.length)
        const newNode = { id: newNodeId, label: newNodeId, options: '' }
        setNodes((prevNodes) => {
            const updatedNodes = [...prevNodes, newNode]
            if (isCompleteGraph) {
                const newEdges = generateCompleteGraphEdges(updatedNodes)
                setEdges(newEdges)
            }
            return updatedNodes
        })
    }

    const handleCompleteGraph = (isChecked) => {
        setIsCompleteGraph(isChecked)
        if (isChecked) {
            const completeEdges = generateCompleteGraphEdges()
            setEdges(completeEdges)
        }
    }

    const generateCompleteGraphEdges = (nodeList = nodes, directed = isDirected) => {
        const newEdges = []
        for (let i = 0; i < nodeList.length; i++) {
            for (let j = 0; j < nodeList.length; j++) {
                if (i !== j) {
                    if (directed) {
                        newEdges.push({
                            id: `edge_${nodeList[i].id}_${nodeList[j].id}`,
                            from: nodeList[i].id,
                            to: nodeList[j].id,
                            label: '',
                            options: '',
                            isDirected: true,
                        })
                    } else if (i < j) {
                        newEdges.push({
                            id: `edge_${nodeList[i].id}_${nodeList[j].id}`,
                            from: nodeList[i].id,
                            to: nodeList[j].id,
                            label: '',
                            options: '',
                            isDirected: false,
                        })
                    }
                }
            }
        }
        return newEdges
    }

    const deleteEdge = (edgeId) => {
        setEdges((prevEdges) => prevEdges.filter((edge) => edge.id !== edgeId))
        setIsCompleteGraph(false)
    }

    const DeleteButton = ({ onClick }) => (
        <button
            onClick={onClick}
            className="flex-shrink-0 flex items-center justify-center w-6 h-6 rounded-full bg-red-600 border border-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 transition-colors duration-200"
            style={{ minWidth: '1.5rem', minHeight: '1.5rem' }}
        >
            <svg
                className="h-4 w-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
            >
                <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                />
            </svg>
        </button>
    )

    const translations = {
        en: {
            title: 'Graph Generator',
            graphOptions: 'Graph Options',
            layout: 'Layout',
            simpleNecklace: 'Simple Necklace',
            springLayout: 'Spring',
            additionalGraphOptions: 'Additional Graph Options',
            directedGraph: 'Directed Graph',
            completeGraph: 'Complete Graph',
            detailedSettings: 'Detailed Settings',
            nodeAppearance: 'Node Appearance',
            nodeShape: 'Node Shape',
            circle: 'Circle',
            rectangle: 'Rectangle',
            diamond: 'Diamond',
            backgroundColor: 'Background Color',
            frameColor: 'Frame Color',
            textColor: 'Text Color',
            globalNodeOptions: 'Global Node Options',
            edgeAppearance: 'Edge Appearance',
            edgeColor: 'Edge Color',
            globalEdgeOptions: 'Global Edge Options',
            addNode: 'Add Node',
            addEdge: 'Add Edge',
            from: 'From',
            to: 'To',
            label: 'Label',
            nodes: 'Nodes',
            edges: 'Edges',
            options: 'Options',
            rotation: 'Rotation',
            directed: 'Directed',
            generateLatexCode: 'Generate LaTeX Code',
            generatedLatexCode: 'LaTeX Code',
            copyToClipboard: 'Copy to Clipboard',
            editInTexlive: 'Edit in TeXLive.net',
            downloadTexFile: 'Download TeX File',
            compileToPdf: 'Compile to PDF',
            compileToSvg: 'Compile to SVG Image',
            eg: 'e.g.,',
        },
        fr: {
            title: 'Générateur de graphes',
            graphOptions: 'Options du graphe',
            layout: 'Disposition',
            simpleNecklace: "Option 'Simple Necklace'",
            springLayout: "Option 'Spring'",
            additionalGraphOptions: 'Options supplémentaires du graphe',
            directedGraph: 'Graphe orienté',
            completeGraph: 'Graphe complet',
            detailedSettings: 'Paramètres détaillés',
            nodeAppearance: 'Apparence des nœuds',
            nodeShape: 'Forme des nœuds',
            circle: 'Cercle',
            rectangle: 'Rectangle',
            diamond: 'Losange',
            backgroundColor: 'Couleur de fond',
            frameColor: 'Couleur du cadre',
            textColor: 'Couleur du texte',
            globalNodeOptions: 'Options globales des nœuds',
            edgeAppearance: 'Apparence des arêtes',
            edgeColor: 'Couleur des arêtes',
            globalEdgeOptions: 'Options globales des arêtes',
            addNode: 'Ajouter un nœud',
            addEdge: 'Ajouter une arête',
            from: 'De',
            to: 'vers',
            label: 'Étiquette',
            nodes: 'Nœuds',
            edges: 'Arêtes',
            options: 'Options',
            rotation: 'Rotation',
            directed: 'Orienté',
            generateLatexCode: 'Générer le code LaTeX',
            generatedLatexCode: 'Code LaTeX',
            copyToClipboard: 'Copier dans le presse-papiers',
            editInTexlive: 'Éditer sur TeXLive.net',
            downloadTexFile: 'Télécharger le fichier TeX',
            compileToPdf: 'Compiler en PDF',
            compileToSvg: 'Compiler en image SVG',
            eg: 'ex :',
        },
    }

    const downloadTexFile = () => {
        const element = document.createElement('a')
        const file = new Blob([latexCode], { type: 'text/plain' })
        element.href = URL.createObjectURL(file)
        element.download = 'graph.tex'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }

    const generateLatex = () => {
        const defaultNodeStyle = [
            `draw=${nodeFrameColor}`,
            nodeShape,
            `nodes={minimum size=2em}`,
            `fill=${nodeBackgroundColor}`,
            `text=${nodeTextColor}`,
            'inner sep=1pt',
        ]
            .filter(Boolean)
            .join(', ')

        const defaultEdgeStyle = [
            'rounded corners',
            `color=${edgeColor}`,
            'nodes={font=\\scriptsize, fill=white, text=black, inner sep=2pt,align=center}',
        ]
            .filter(Boolean)
            .join(', ')

        const nodeDefinitions = nodes
            .map((node) => {
                const nodeId = node.label || node.id
                const nodeOptions = node.options ? `[${node.options}]` : ''
                return `${nodeId}${nodeOptions}`
            })
            .join(',\n       ')

        const bidirectionalPairs = new Set()
        edges.forEach((edge) => {
            const reverseEdge = edges.find((e) => e.from === edge.to && e.to === edge.from)
            if (reverseEdge) {
                bidirectionalPairs.add(`${edge.from}-${edge.to}`)
            }
        })

        const edgeDefinitions = edges
            .map((edge) => {
                const fromNode = nodes.find((n) => n.id === edge.from)?.label || edge.from
                const toNode = nodes.find((n) => n.id === edge.to)?.label || edge.to
                let options = []

                if (fromNode === toNode) {
                    options.push('loop')
                    if (edge.loopRotation) {
                        options.push(`rotate=${edge.loopRotation}`)
                    }
                } else if (edge.isDirected) {
                    if (bidirectionalPairs.has(`${edge.from}-${edge.to}`)) {
                        const bendAngle = 10
                        options.push(`bend left=${bendAngle}`)
                    } else {
                        options.push('bend left=0')
                    }

                    options.push('->')
                }

                if (edge.options) {
                    options = options.concat(edge.options.split(',').map((opt) => opt.trim()))
                }

                let optionsString = options.length > 0 ? options.join(', ') : ''
                if (edge.label) {
                    optionsString = optionsString ? `"${edge.label}", ${optionsString}` : `"${edge.label}"`
                }

                const optionsPart = optionsString ? ` [${optionsString}]` : ''

                return `${fromNode} --${optionsPart} ${toNode}`
            })
            .join(',\n       ')

        let code = `% !TeX program = lualatex
\\documentclass[border=20pt]{standalone}
\\usepackage[svgnames]{xcolor}
\\usepackage{amsmath}
\\usepackage[upgreek]{frenchmath}
\\usepackage{tikz}
\\usetikzlibrary{graphs,graphdrawing,quotes,bending}
\\usegdlibrary{circular,force,layered,routing}
\\tikzset{
  graphs/custom/.style={
    node distance=2.5cm,
    nodes={${defaultNodeStyle}, ${globalNodeOptions}},
    edges={${defaultEdgeStyle}, ${globalEdgeOptions}},
    edge quotes mid,
    ${graphOptions} % Include graph-level options here
  },
  every loop/.style={min distance=20mm, looseness=5},
  baseline,
}
\\begin{document}
\\begin{tikzpicture}
    [scale=.7]
    \\graph[custom, ${graphLayout}]{
       ${nodeDefinitions},
       ${edgeDefinitions}
    };
\\end{tikzpicture}
\\end{document}`

        setLatexCode(code)
    }

    const compileLatex = () => {
        const form = document.createElement('form')
        form.method = 'POST'
        form.action = 'https://texlive.net/cgi-bin/latexcgi'
        form.enctype = 'multipart/form-data'
        form.target = '_blank'

        const addField = (name, value) => {
            const input = document.createElement('input')
            input.type = 'hidden'
            input.name = name
            input.value = value
            form.appendChild(input)
        }

        addField('engine', 'lualatex')
        addField('filename[]', 'document.tex')
        addField('filecontents[]', latexCode)
        addField('return', 'pdfjs')

        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
    }

    const compileToSVG = () => {
        const form = document.createElement('form')
        form.method = 'POST'
        form.action = 'https://texlive.net/cgi-bin/latexcgi'
        form.enctype = 'multipart/form-data'
        form.target = '_blank'

        const addField = (name, value) => {
            const input = document.createElement('input')
            input.type = 'hidden'
            input.name = name
            input.value = value
            form.appendChild(input)
        }

        addField('engine', 'lualatex')
        addField('filename[]', 'document.tex')
        addField('filecontents[]', latexCode)
        addField('return', 'make4ht')

        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
    }

    const editInTeXlive = () => {
        const fullLatexCode = latexCode
        const encodedCode = encodeURIComponent(fullLatexCode)
        const texliveUrl = `https://texlive.net/run?${encodedCode}`
        window.open(texliveUrl, '_blank')
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(latexCode).then(
            () => {
                setNotification('Code LaTeX copié dans le presse-papier !')
                setTimeout(() => setNotification(null), 2000)
            },
            (err) => {
                console.error('Could not copy text: ', err)
                setNotification('Failed to copy LaTeX')
                setTimeout(() => setNotification(null), 2000)
            }
        )
    }

    return (
        <div className="min-h-screen">
            <header className="bg-[#3f51b5] shadow-sm">
                <div className="max-w-8xl mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
                    <div className="w-1/3 flex justify-start">
                        <a
                            href="https://apps.edulatex.xyz"
                            rel="noopener noreferrer"
                            className="text-white hover:text-white transition-colors duration-200"
                        >
                            <FontAwesomeIcon icon={faHome} className="text-lg transform scale-95" />
                        </a>
                    </div>
                    <h1 className="text-white text-xl font-bold w-1/3 text-center">{t('title')}</h1>
                    <div className="w-1/3 flex justify-end">
                        <LanguageSelector currentLanguage={language} toggleLanguage={toggleLanguage} t={t} />
                    </div>
                </div>
            </header>

            <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="px-4 py-6 sm:px-0">
                    <div className="flex flex-col lg:flex-row gap-6">
                        {/* Left Column: Nodes and Edges */}
                        <div className="lg:w-2/3 space-y-6">
                            {/* Nodes Section */}
                            <section className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('nodes')}</h2>
                                {nodes.map((node, index) => (
                                    <div key={node.id} className="node-input flex items-center space-x-2 mb-2">
                                        {/* <Input value={node.id} readOnly className="w-16 border-0" placeholder="ID" /> */}
                                        <EnhancedInput
                                            value={node.label}
                                            onChange={(e) => updateNode(index, 'label', e.target.value)}
                                            className="w-24"
                                            placeholder={t('label')}
                                        />
                                        <EnhancedInput
                                            value={node.options}
                                            onChange={(e) => updateNode(index, 'options', e.target.value)}
                                            className="flex-grow"
                                            placeholder={t('Options')}
                                        />
                                        <DeleteButton onClick={() => deleteNode(index)} />
                                    </div>
                                ))}
                                <div className="flex justify-center mt-5">
                                    <AddButton onClick={addNode} title={t('addNode')} />
                                </div>
                            </section>

                            {/* Edges Section */}
                            <section className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('edges')}</h2>
                                {edges.map((edge) => (
                                    <div key={edge.id} className="edge-input flex items-center space-x-2 mb-2">
                                        <EnhancedInput
                                            ref={(el) => (edgeRefs.current[edge.id] = el)}
                                            value={edge.from}
                                            onChange={(e) => updateEdge(edge.id, 'from', e.target.value)}
                                            placeholder={t('from')}
                                            className="w-16"
                                        />
                                        <EnhancedInput
                                            value={edge.to}
                                            onChange={(e) => updateEdge(edge.id, 'to', e.target.value)}
                                            placeholder={t('to')}
                                            className="w-16"
                                        />
                                        <EnhancedInput
                                            value={edge.label}
                                            onChange={(e) => updateEdge(edge.id, 'label', e.target.value)}
                                            placeholder={t('label')}
                                            className="w-20"
                                        />
                                        <EnhancedInput
                                            value={edge.options}
                                            onChange={(e) => updateEdge(edge.id, 'options', e.target.value)}
                                            placeholder="Options"
                                            className="flex-grow min-w-0"
                                        />
                                        {edge.from === edge.to && (
                                            <EnhancedInput
                                                type="number"
                                                value={edge.loopRotation || ''}
                                                onChange={(e) =>
                                                    updateEdge(
                                                        edge.id,
                                                        'loopRotation',
                                                        e.target.value ? parseInt(e.target.value) : ''
                                                    )
                                                }
                                                placeholder={t('rotation')}
                                                className="w-24"
                                            />
                                        )}
                                        <label className="flex items-center space-x-2 whitespace-nowrap">
                                            <input
                                                type="checkbox"
                                                checked={edge.isDirected}
                                                onChange={(e) => updateEdge(edge.id, 'isDirected', e.target.checked)}
                                                className="form-checkbox h-5 w-5 text-blue-600"
                                            />
                                            <span>{t('directed')}</span>
                                        </label>
                                        <DeleteButton onClick={() => deleteEdge(edge.id)} />
                                    </div>
                                ))}
                                <div className="flex justify-center mt-5">
                                    <AddButton onClick={addEdge} title={t('addEdge')} />
                                </div>
                            </section>
                        </div>

                        <style jsx>{`
                            .value-input,
                            .value-select {
                                color: #a0aec0; /* Light gray for values, same as placeholder color */
                            }
                            .value-input::placeholder {
                                color: #a0aec0; /* Same light gray for placeholders */
                            }
                            /* Ensuring the select options are also light */
                            .value-select option {
                                color: #a0aec0;
                            }
                        `}</style>

                        {/* Right Column:  Settings */}
                        <div className="lg:w-1/3">
                            {/* Important Options Section */}
                            <section className="bg-white shadow rounded-lg p-4 mb-4">
                                <h3 className="text-lg font-semibold text-gray-900 mb-3">{t('graphOptions')}</h3>
                                <div className="space-y-4">
                                    <div>
                                        <label
                                            htmlFor="graph-layout"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {t('layout')}
                                        </label>
                                        <select
                                            id="graph-layout"
                                            value={graphLayout}
                                            onChange={(e) => setGraphLayout(e.target.value)}
                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-sm text-gray-400 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                                        >
                                            <option value="simple necklace layout">{t('simpleNecklace')}</option>
                                            <option value="spring layout">{t('springLayout')}</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="graph-options"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {t('additionalGraphOptions')}
                                        </label>
                                        <input
                                            type="text"
                                            id="graph-options"
                                            value={graphOptions}
                                            onChange={(e) => setGraphOptions(e.target.value)}
                                            className="mt-1 block w-full px-3 py-2 text-sm text-gray-400 border-gray-300 rounded-md"
                                            placeholder={t('eg') + ' node distance=3cm, layered layout'}
                                        />
                                    </div>
                                    <div className="flex items-center space-x-4">
                                        <div className="flex items-center">
                                            <input
                                                id="is-directed"
                                                type="checkbox"
                                                checked={isDirected}
                                                onChange={(e) => updateGlobalDirected(e.target.checked)}
                                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor="is-directed" className="ml-2 block text-sm text-gray-700">
                                                {t('directedGraph')}
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                id="is-complete-graph"
                                                type="checkbox"
                                                checked={isCompleteGraph}
                                                onChange={(e) => handleCompleteGraph(e.target.checked)}
                                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                            />
                                            <label
                                                htmlFor="is-complete-graph"
                                                className="ml-2 block text-sm text-gray-700"
                                            >
                                                {t('completeGraph')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* Detailed Settings Accordion */}
                            <section className="bg-white shadow rounded-lg overflow-hidden mt-6">
                                <div
                                    className="flex justify-between items-center p-4 cursor-pointer"
                                    onClick={() => setIsSettingsPanelOpen(!isSettingsPanelOpen)}
                                >
                                    <h2 className="text-lg font-semibold text-gray-900">{t('detailedSettings')}</h2>
                                    {isSettingsPanelOpen ? (
                                        <ChevronUp className="h-5 w-5 text-gray-500" />
                                    ) : (
                                        <ChevronDown className="h-5 w-5 text-gray-500" />
                                    )}
                                </div>
                                <div
                                    className={`transition-all duration-300 ease-in-out ${
                                        isSettingsPanelOpen ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'
                                    } overflow-hidden`}
                                >
                                    <div className="p-4 space-y-6">
                                        {/* Node Appearance */}
                                        <div>
                                            <h3 className="text-md font-medium text-gray-900 mb-3">
                                                {t('nodeAppearance')}
                                            </h3>
                                            <div className="space-y-3">
                                                <div>
                                                    <label htmlFor="node-shape" className="block text-sm text-gray-700">
                                                        {t('nodeShape')}
                                                    </label>
                                                    <select
                                                        id="node-shape"
                                                        value={nodeShape}
                                                        onChange={(e) => setNodeShape(e.target.value)}
                                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-sm text-gray-400 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                                                    >
                                                        <option value="circle">{t('circle')}</option>
                                                        <option value="rectangle">{t('rectangle')}</option>
                                                        <option value="diamond">{t('diamond')}</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="node-background-color"
                                                        className="block text-sm text-gray-700"
                                                    >
                                                        {t('backgroundColor')}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="node-background-color"
                                                        value={nodeBackgroundColor}
                                                        onChange={(e) => setNodeBackgroundColor(e.target.value)}
                                                        className="mt-1 block w-full px-3 py-2 text-sm text-gray-400 border-gray-300 rounded-md"
                                                        placeholder="e.g., white, #FFFFFF"
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="node-frame-color"
                                                        className="block text-sm text-gray-700"
                                                    >
                                                        {t('frameColor')}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="node-frame-color"
                                                        value={nodeFrameColor}
                                                        onChange={(e) => setNodeFrameColor(e.target.value)}
                                                        className="mt-1 block w-full px-3 py-2 text-sm text-gray-400 border-gray-300 rounded-md"
                                                        placeholder="e.g., black, #000000"
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="node-text-color"
                                                        className="block text-sm text-gray-700"
                                                    >
                                                        {t('textColor')}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="node-text-color"
                                                        value={nodeTextColor}
                                                        onChange={(e) => setNodeTextColor(e.target.value)}
                                                        className="mt-1 block w-full px-3 py-2 text-sm text-gray-400 border-gray-300 rounded-md"
                                                        placeholder="e.g., black, #000000"
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="global-node-options"
                                                        className="block text-sm text-gray-700"
                                                    >
                                                        {t('globalNodeOptions')}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="global-node-options"
                                                        value={globalNodeOptions}
                                                        onChange={(e) => setGlobalNodeOptions(e.target.value)}
                                                        className="mt-1 block w-full px-3 py-2 text-sm text-gray-400 border-gray-300 rounded-md"
                                                        placeholder={t('eg') + ' minimum size=1cm, draw'}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Edge Appearance */}
                                        <div>
                                            <h3 className="text-md font-medium text-gray-900 mb-3">
                                                {t('edgeAppearance')}
                                            </h3>
                                            <div className="space-y-3">
                                                <div>
                                                    <label htmlFor="edge-color" className="block text-sm text-gray-700">
                                                        {t('edgeColor')}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="edge-color"
                                                        value={edgeColor}
                                                        onChange={(e) => setEdgeColor(e.target.value)}
                                                        className="mt-1 block w-full px-3 py-2 text-sm text-gray-400 border-gray-300 rounded-md"
                                                        placeholder="e.g., black, #000000"
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="global-edge-options"
                                                        className="block text-sm text-gray-700"
                                                    >
                                                        {t('globalEdgeOptions')}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="global-edge-options"
                                                        value={globalEdgeOptions}
                                                        onChange={(e) => setGlobalEdgeOptions(e.target.value)}
                                                        className="mt-1 block w-full px-3 py-2 text-sm text-gray-400 border-gray-300 rounded-md"
                                                        placeholder={t('eg') + ' thick, ->'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                    {/* Generate LaTeX Button */}
                    <div className="mt-8 flex justify-center">
                        <button
                            onClick={generateLatex}
                            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-[#3f51b5] hover:bg-[#303f9f] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3f51b5]"
                        >
                            {t('generateLatexCode')}
                        </button>
                    </div>

                    {/* LaTeX Output Section */}
                    {latexCode && (
                        <section className="mt-8 bg-white shadow rounded-lg p-6">
                            <div className="relative">
                                <SyntaxHighlighter
                                    language="latex"
                                    style={tomorrow}
                                    customStyle={{
                                        backgroundColor: '#ffffff',
                                        padding: '1rem',
                                        borderRadius: '0.5rem',
                                        fontSize: '0.875rem',
                                        lineHeight: '1.5',
                                        maxHeight: '400px',
                                        overflowY: 'auto',
                                    }}
                                >
                                    {latexCode}
                                </SyntaxHighlighter>
                            </div>
                            <div className="mt-4 flex flex-wrap justify-center space-x-4">
                                <button
                                    onClick={copyToClipboard}
                                    className="mt-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3f51b5]"
                                >
                                    {t('copyToClipboard')}
                                </button>
                                <button
                                    onClick={editInTeXlive}
                                    className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#4caf50] hover:bg-[#4caf50] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4caf50]"
                                >
                                    {t('editInTexlive')}
                                </button>
                                <button
                                    onClick={compileLatex}
                                    className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#3f51b5] hover:bg-[#303f9f] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3f51b5]"
                                >
                                    {t('compileToPdf')}
                                </button>
                                <button
                                    onClick={compileToSVG}
                                    className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#4caf50] hover:bg-[#4caf50] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4caf50]"
                                >
                                    {t('compileToSvg')}
                                </button>
                                <button
                                    onClick={downloadTexFile}
                                    className="mt-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3f51b5]"
                                >
                                    {t('downloadTexFile')}
                                </button>
                            </div>
                        </section>
                    )}
                </div>
                {notification && (
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-200 text-green-900 py-2 px-4 rounded-md shadow-lg notification">
                        {notification}
                    </div>
                )}
            </main>
        </div>
    )
}

export default App
