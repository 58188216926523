import React, { forwardRef } from 'react'

const EnhancedInput = forwardRef(({ className, ...props }, ref) => {
    const handleFocus = (event) => {
        event.target.select()
    }

    return (
        <input
            ref={ref}
            className={`text-sm p-2 text-gray-600 bg-[#ECEEF6] rounded-md border border-white focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-[#7a87c7] ${className}`}
            onFocus={handleFocus}
            {...props}
        />
    )
})

export default EnhancedInput
